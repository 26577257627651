import '../App.css';
import React from 'react'

function Mobile() {
  return (
    <div id="mobileOverlay">
      <p id="mobileType">Please visit this page on desktop to use the GLSL editor</p>
    </div>
  );
}

export default Mobile;
